import React, {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  addEdge,
  Connection,
  Node,
  Edge,
  useReactFlow,
  ReactFlowProvider,
  NodeMouseHandler,
} from '@xyflow/react';

import { v4 as uuidv4 } from 'uuid';

import '@xyflow/react/dist/style.css';
import './style.css';

import nodeTypes from './nodes/nodeTypes';
import {
  Breadcrumb,
  Button,
  Empty,
  Flex,
  message,
  Modal,
  Spin,
  Typography,
  Upload,
} from 'antd';
import ToolsPanel, { ToolType } from './ToolsPanel';
import { Link, useParams } from 'react-router-dom';
import {
  getGraph,
  getOperatorNodesDescriptions,
  GraphListEntry,
  listGraphs,
  NodeDescription,
  updateGraph,
} from '../client';
import { AxiosError, CanceledError } from 'axios';
import {
  CaretRightOutlined,
  ExportOutlined,
  ImportOutlined,
} from '@ant-design/icons';
import { IONodeType } from './nodes/IONode';
import { IfNodeType } from './nodes/IfNode';
import { SubgraphNodeType } from './nodes/SubgraphNode';
import { OperatorNodeType, OperatorParamType } from './nodes/OperatorNode';
import ErrorPlaceholder from './ErrorPlaceholder';
import TestPanel from './TestPanel';
import { AppContext } from '../context';

const initialNodes: Node[] = [
  {
    id: 'input',
    position: { x: 300, y: 0 },
    data: {},
    type: 'IO',
  } as IONodeType,
  {
    id: 'output',
    position: { x: 300, y: 400 },
    data: { isOutput: true },
    type: 'IO',
  } as IONodeType,
];
const initialEdges: Edge[] = [];

function updateNodeData(node: Node, update: Record<string, any>): Node {
  return { ...node, data: { ...node.data, ...update } };
}

function updateNodeInArray(
  nodes: Node[],
  nodeId: string,
  update: Record<string, any>
): Node[] {
  return nodes.map((node: Node) => {
    if (node.id !== nodeId) return node;
    return updateNodeData(node, update);
  });
}

function createNode(
  toolType: ToolType,
  x: number,
  y: number,
  operatorNodesDescr: NodeDescription[]
): Node | null {
  switch (toolType) {
    case 'if':
      return {
        id: uuidv4(),
        position: { x, y },
        data: {},
        type: 'If',
      } as IfNodeType;
    case 'subgraph':
      return {
        id: uuidv4(),
        position: { x, y },
        data: {
          type: 'subgraph',
          graphs: [],
          isMultiInput: false,
          isMultiOutput: false,
        },
        type: 'Subgraph',
      } as SubgraphNodeType;
    case 'foreach':
      return {
        id: uuidv4(),
        position: { x, y },
        data: {
          type: 'foreach',
          graphs: [],
          isMultiInput: true,
          isMultiOutput: true,
        },
        type: 'Subgraph',
      } as SubgraphNodeType;
  }

  for (const nodeDescr of operatorNodesDescr) {
    if (nodeDescr.name !== toolType) {
      continue;
    }
    return {
      id: uuidv4(),
      position: { x, y },
      data: {
        operatorName: nodeDescr.name,
        isMultiInput: nodeDescr.input_type === 'multi',
        isMultiOutput: nodeDescr.output_type === 'multi',
        label: nodeDescr.label,
        params: nodeDescr.parameters,
      },
      type: 'Operator',
    } as OperatorNodeType;
  }

  return null;
}

function GraphEditor() {
  const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);

  const [graphName, setGraphName] = useState('');
  const [graphs, setGraphs] = useState<GraphListEntry[]>([]);
  const [nodesDescriptions, setNodesDescriptions] = useState<NodeDescription[]>(
    []
  );

  const [loading, setLoading] = useState(false);
  const [errorFetching, setErrorFetching] = useState(false);

  const saveAbortController = useRef<AbortController | null>(null);
  const [changed, setChanged] = useState(false);
  const [saveRequested, setSaveRequested] = useState(false);
  const [saving, setSaving] = useState(false);

  const { screenToFlowPosition } = useReactFlow();

  const [selectedTool, setSelectedTool] = useState<ToolType>('select');

  const { graphId = '' } = useParams();
  const { projectId, projectName } = useContext(AppContext);

  const [messageApi, contextHolder] = message.useMessage();

  const [testOpen, setTestOpen] = useState(false);
  const [importOpen, setImportOpen] = useState(false);

  const exportLinkRef = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    const abortController = new AbortController();
    if (!projectId) return;
    setLoading(true);
    (async () => {
      try {
        const graphData = await getGraph(
          projectId,
          graphId || '',
          abortController
        );
        const graphsList = await listGraphs(projectId, abortController);
        const operatorNodesDescriptions = await getOperatorNodesDescriptions(
          abortController
        );
        setGraphs(
          graphsList
            .map(({ id, name, ...rest }) => ({ id, name: name || id, ...rest }))
            .filter(({ id }) => id !== graphId)
        );
        setNodesDescriptions(operatorNodesDescriptions);
        setLoading(false);
        setGraphName(graphData.name || '');
        if (!graphData.graph) {
          setNodes(initialNodes);
          setEdges(initialEdges);
          return;
        }
        setNodes(graphData.graph.nodes);
        setEdges(graphData.graph.edges);
      } catch (error) {
        if (error instanceof CanceledError) return;
        setLoading(false);
        setErrorFetching(true);
        if (error instanceof AxiosError && error.status === 404) {
          messageApi.error('Graph not found');
        } else {
          messageApi.error('Error fetching graph');
          console.log('Error fetching graph:', error);
        }
      }
    })();
    return () => {
      abortController.abort();
    };
  }, [messageApi, projectId, graphId, setNodes, setEdges]);

  useEffect(() => {
    setChanged(true);
  }, [nodes, edges]);

  useEffect(() => {
    saveAbortController.current?.abort();
    console.log('save aborted');
  }, []);

  useEffect(() => {
    if (loading || errorFetching) return;
    const saveTimeoutId = window.setInterval(() => {
      setSaveRequested(true);
    }, 5000);
    return () => {
      window.clearInterval(saveTimeoutId);
      console.log('Timeout cancelled');
    };
  }, [loading, errorFetching]);

  useEffect(() => {
    if (
      loading ||
      errorFetching ||
      !changed ||
      !saveRequested ||
      saving ||
      !projectId
    ) {
      return;
    }
    setSaving(true);
    setChanged(false);
    setSaveRequested(false);
    (async () => {
      const abortController = new AbortController();
      saveAbortController.current = abortController;
      try {
        console.log('saving');
        await updateGraph(
          projectId,
          graphId,
          {
            id: graphId,
            graph: { id: graphId, nodes, edges },
          },
          abortController
        );
        console.log('saved');
      } catch (error) {
        if (error instanceof CanceledError) {
          console.log('save cancelled');
          return;
        }
        messageApi.error('Error saving graph');
        console.log('Error saving graph:', error);
      }
      setSaving(false);
    })();
  }, [
    loading,
    errorFetching,
    changed,
    saveRequested,
    saving,
    projectId,
    graphId,
    nodes,
    edges,
    messageApi,
    saveAbortController,
  ]);

  // const onNodesChange = useCallback(
  //   (changes: any) => {
  //     setNodes((nds) => applyNodeChanges(changes, nds));
  //   },
  //   [setNodes]
  // );
  // const onEdgesChange = useCallback(
  //   (changes: any) => {
  //     setEdges((eds) => applyEdgeChanges(changes, eds));
  //   },
  //   [setEdges]
  // );

  const onConnect = useCallback(
    (params: Connection) => {
      const sourceNode = nodes.find((node) => node.id === params.source);
      const targetNode = nodes.find((node) => node.id === params.target);
      if (!(sourceNode && targetNode)) return;
      if (sourceNode.data.isMultiOutput && !targetNode.data.isMultiInput) {
        return;
      }
      setEdges((eds) => addEdge(params, eds));
    },
    [nodes, setEdges]
  );

  const onBeforeDelete = useCallback(
    async ({ nodes, edges }: { nodes: Node[]; edges: Edge[] }) => ({
      nodes: nodes.filter((node) => node.type !== 'IO'),
      edges,
    }),
    []
  );

  const onSubgraphChange = useCallback(
    (nodeId: string, subgraphId: string) => {
      setNodes((nds) =>
        updateNodeInArray(nds, nodeId, {
          subgraphId,
        })
      );
    },
    [setNodes]
  );

  const onParamsChange = useCallback(
    (nodeId: string, params: OperatorParamType[]) => {
      setNodes((nds) => updateNodeInArray(nds, nodeId, { params }));
    },
    [setNodes]
  );

  const processedNodes = useMemo<Node[]>(
    () =>
      nodes.map((node: Node) => {
        if (node.type === 'Subgraph') {
          const update: Record<string, any> = { onSubgraphChange };
          if (graphs) {
            update.graphs = graphs;
            const subgraphId = node.data.subgraphId;
            console.log('subgraphId', subgraphId);
            if (subgraphId) {
              const subgraph = graphs.find((graph) => graph.id === subgraphId);
              console.log('subgraph', subgraph);
              update.isMultiInput = subgraph && subgraph.isMultiInput;
              update.isMultiOutput = subgraph && subgraph.isMultiOutput;
            }
          }
          return updateNodeData(node, update);
        }
        if (node.type === 'Operator') {
          return updateNodeData(node, { onParamsChange });
        }
        return node;
      }),
    [nodes, graphs, onSubgraphChange, onParamsChange]
  );

  const processedEdges = useMemo<Edge[]>(
    () =>
      edges.map((edge: Edge) => {
        const sourceNode = nodes.find((node) => node.id === edge.source);
        const targetNode = nodes.find((node) => node.id === edge.target);
        if (sourceNode?.data.isMultiOutput) {
          if (targetNode?.data.isMultiInput) {
            return {
              ...edge,
              className: 'multi-edge',
            };
          } else {
            return {
              ...edge,
              className: 'error-edge',
            };
          }
        }
        // TODO maybe optimize
        if (!targetNode?.data.isMultiInput) {
          const targetInputEdges = edges.filter(
            (targetEdge) =>
              targetEdge.target === edge.target &&
              targetEdge.targetHandle === edge.targetHandle
          );
          if (targetInputEdges.length > 1) {
            return {
              ...edge,
              className: 'conditional-edge',
            };
          }
        }

        return edge;
      }),
    [edges, nodes]
  );

  const onPaneClick = useCallback(
    (event: MouseEvent) => {
      if (
        selectedTool === 'select' ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
      )
        return;

      event.preventDefault();

      const { x, y } = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode = createNode(selectedTool, x, y, nodesDescriptions);
      if (newNode) {
        setNodes((nds: Node[]) => [...nds, newNode]);
      }
      setSelectedTool('select');
    },
    [selectedTool, setNodes, screenToFlowPosition, nodesDescriptions]
  );

  const onSelectTool = (toolType: ToolType) => {
    setSelectedTool(toolType);
  };

  const changeNodeIOType = useCallback<NodeMouseHandler>(
    (event, node) => {
      if (node.type !== 'IO') return;
      const ioNode = node as IONodeType;
      event.preventDefault();
      let update = {};
      if (ioNode.data.isOutput) {
        update = { isMultiInput: !ioNode.data.isMultiInput };
      } else {
        update = { isMultiOutput: !ioNode.data.isMultiOutput };
      }
      setNodes((nds) => updateNodeInArray(nds, node.id, update));
    },
    [setNodes]
  );

  const onExportClick = () => {
    if (!exportLinkRef.current) return;
    const exportData = {
      nodes,
      edges,
      id: graphId,
    };
    const exportText = JSON.stringify(exportData);
    const blob = new Blob([exportText], { type: 'application/json' });
    const fileName = `${projectName}-${new Date().toISOString()}.json`;
    const url = window.URL.createObjectURL(blob);
    try {
      exportLinkRef.current.href = url;
      exportLinkRef.current.download = fileName;
      exportLinkRef.current.click();
    } finally {
      window.URL.revokeObjectURL(url);
    }
  };

  const importFromJson = (jsonData: string) => {
    try {
      const graphData = JSON.parse(jsonData);
      const { nodes, edges } = graphData;
      if (!nodes || !edges) {
        messageApi.error('Import error');
        return;
      }
      setNodes(nodes);
      setEdges(edges);
      console.log(graphData);
    } catch (error) {
      messageApi.error('Import error');
      console.log('Import error:', error);
    }
  };

  if (loading) {
    return (
      <Flex align="center" justify="center" className="lpe-placeholder">
        {contextHolder}
        <Empty description="Loading" image={<Spin size="large" />} />
      </Flex>
    );
  }

  if (errorFetching) {
    return <ErrorPlaceholder>{contextHolder}</ErrorPlaceholder>;
  }

  return (
    <>
      {contextHolder}
      <ReactFlow
        nodes={processedNodes}
        edges={processedEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onBeforeDelete={onBeforeDelete}
        onConnect={onConnect}
        onPaneClick={onPaneClick}
        onNodeContextMenu={changeNodeIOType}
        nodeTypes={nodeTypes}
        deleteKeyCode={['Delete', 'Backspace']}
        fitView
      >
        <Breadcrumb
          className="graph-editor-breadcrumb"
          items={[
            {
              title: <Link to="/graphs">Graphs</Link>,
            },
            {
              title: graphName,
            },
          ]}
        />
        <div className="graph-tools-container">
          <ToolsPanel
            selctedTool={selectedTool}
            onSelectTool={onSelectTool}
            nodesDescriptions={nodesDescriptions}
          />
        </div>
        <div className={testOpen ? 'graph-test-panel-container' : 'lpe-hidden'}>
          <TestPanel graphId={graphId} onClose={() => setTestOpen(false)} />
        </div>
        <div className="graph-extra-tools">
          <Button
            size="large"
            icon={<CaretRightOutlined />}
            onClick={() => setTestOpen(true)}
          >
            Run
          </Button>
          <Button
            size="large"
            icon={<ImportOutlined />}
            onClick={() => setImportOpen(true)}
          >
            Import
          </Button>
          <Button
            size="large"
            icon={<ExportOutlined />}
            onClick={onExportClick}
          >
            Export
          </Button>
          <a style={{ display: 'none' }} ref={exportLinkRef} />
        </div>
        {/* )} */}
      </ReactFlow>
      <Modal
        open={importOpen}
        onCancel={() => setImportOpen(false)}
        closeIcon={null}
        footer={null}
      >
        <Upload.Dragger
          className="import-dropzone"
          multiple={false}
          beforeUpload={async (file) => {
            importFromJson(await file.text())
            setImportOpen(false);
            return false;
          }}
          maxCount={1}
          fileList={[]}
        >
          <Typography.Title level={4}>
            <ImportOutlined /> Import
          </Typography.Title>
        </Upload.Dragger>
      </Modal>
    </>
  );
}

export default function GraphEditorContainer() {
  return (
    <ReactFlowProvider>
      <GraphEditor />
    </ReactFlowProvider>
  );
}
