import { Flex, Input, Form, Button, Card, theme, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { getUser, signUpGoogle, updateAuthToken } from '../client';
import { AxiosError, CanceledError } from 'axios';
import { AppContext } from '../context';
import { GoogleLogin } from '@react-oauth/google';

const requiredRules = [{ required: true, message: 'Token required!' }];

type CredentialsType = { token?: string; type?: 'token' | 'google' };

export default function Auth() {
  const { token: themeToken } = theme.useToken();
  const [processing, setProcessing] = useState(false);
  const [credentials, setCredentials] = useState<CredentialsType>({});
  const [messageApi, contextHolder] = message.useMessage();
  const { setUser } = useContext(AppContext);

  useEffect(() => {
    if (!processing) return;
    const abortController = new AbortController();
    (async () => {
      try {
        updateAuthToken(credentials.token || null);
        const user = await getUser(abortController);
        setUser(user);
      } catch (error) {
        if (error instanceof CanceledError) return;
        if (
          error instanceof AxiosError &&
          error.status === 401 &&
          credentials.type === 'google'
        ) {
          try {
            const user = await signUpGoogle(
              credentials.token || '',
              abortController
            );
            setUser(user);
          } catch (error) {
            if (error instanceof CanceledError) return;
            messageApi.error('Sign up error');
            console.log('Sign up error:', error);
          }
        } else {
          messageApi.error('Login error');
          console.log('Login error:', error);
        }
      }
      setProcessing(false);
    })();
    return () => {
      abortController.abort();
    };
  }, [processing, messageApi, credentials, setUser]);

  return (
    <Flex
      className="auth-container"
      justify="center"
      align="center"
      style={{ backgroundColor: themeToken.colorPrimary }}
    >
      {contextHolder}
      <div>
        <Card>
          <Form
            className="token-auth-form"
            onFinish={(newParams: Record<string, any>) => {
              setCredentials(newParams as CredentialsType);
              setProcessing(true);
            }}
          >
            <Form.Item
              key={'param-token'}
              name="token"
              label="Token"
              rules={requiredRules}
              required={false}
            >
              <Input.Password autoComplete="password" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={processing}>
                Login
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <Card className="google-login-card">
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              setCredentials({
                token: credentialResponse.credential,
                type: 'google',
              });
              setProcessing(true);
            }}
            onError={() => {
              console.log('Login Failed');
              messageApi.error('Login Failed');
            }}
          />
        </Card>
      </div>
    </Flex>
  );
}
